import React from 'react'
import PropTypes from 'prop-types'
import Seo from '~components/Seo'
import Section from '~components/Section'
import styled from '@emotion/styled'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { useQueryParam, StringParam } from 'use-query-params'
import DataViewer from '~utils/DataViewer'
import resolveLink from '~utils/resolveLink'
import { mobile, tablet } from '~styles/global'
import { useFlexSearch } from 'react-use-flexsearch'

const Search = ({ data }) => {
	const [term, setTerm] = useQueryParam('q', StringParam)
	const { index, store } = data.localSearchPages
	const results = useFlexSearch(term, index, store, {
		language: 'en',
		tokenize: 'full'
	})

	return (
		<>
			<Seo
				title="Search"
			/>
			<Results>
				{term && results.length > 0 &&
					<Tally>{results.length} result{results.length > 1 ? 's' : ''}</Tally>
				}
				{results.map(item => (
					<Result key={item.id} to={resolveLink(item)} item={item}>
						<Heading>{item.title}</Heading>
						{item.metaDescription && 
							<p>{item.metaDescription}</p>
						}
					</Result>
				))}
				{term && results.length === 0 &&
					<Empty>Sorry, no results.</Empty>
				}
			</Results>
			<DataViewer data={results} name='results' />
		</>
	)
}

export const query = graphql`
	{
		localSearchPages {
			store
			index
		}
	}
`

Search.propTypes = {
	data: PropTypes.object,
}

const Result = styled(Link)`
	display: block;
	grid-column: span 12;
	box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
	padding: var(--l);
	max-width: 1053px;
	margin: 0 auto var(--m);
	box-sizing: border-box;
	width: 100%;
	p {
		max-width: 800px;
	}
`

const Heading = styled.h4`
	margin-bottom: var(--xxs);
`

const Results = styled(Section)`
	min-height: 100vh;
	margin-top: 200px;
	margin-bottom: var(--xxl);
	${mobile}{
		margin-top: 250px;
	}
`

const Tally = styled.h5`
	grid-column: span 12;
	margin: 0 auto var(--m);
	max-width: 1053px;
	width: 100%;
`

const Empty = styled.h5`
	grid-column: span 12;
	text-align: center;
	
`

export default Search
